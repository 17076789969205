import { Container,Row } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {
    return(
        <Container className='site-footer'>
            <footer>
            <Row >
                 <Link  className="text-center" style={{color: '#a7a9ac',fontFamily: 'Diphylleia', fontWeight: 'bolder' }} to='https://www.bhhsnv.com/privacy-policy'>Privacy Policy</Link>
            </Row>
                <p className="center">© Anthony Madaffari REALTOR 2025.<br/> All rights reserved.</p>
            </footer>
        </Container>
    );      
};

export default Footer;