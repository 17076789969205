import {Button, ButtonGroup, Container, Row} from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeEmbed from "./YoutubeEmbed";
import { Link } from "react-router-dom";

const HeroContent = () => {
    return(
    
            <Container style={{background:'#ECE3E5'}}>
                <Row>
                    <h1 className='text-center' style={{font: 'Diphylleia', fontSize: '1.1em', marginTop: '2%'}}><strong>Welcome to Las Vegas Housing Market Report – Your Premier Source for Insights into the Dynamic Real Estate Landscape of Las Vegas, Nevada!</strong></h1>
               </Row>
               <Row className="row-content1">
                    <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1%'}}>At <strong>Las Vegas Housing Market Report</strong>, we deliver in-depth analyses, the latest market trends, and valuable data to empower both buyers and sellers in navigating the vibrant Las Vegas housing market. Stay ahead with regular updates featuring insightful analysis and expert commentary, designed to guide you in making informed decisions in your real estate journey.</h2>
               </Row>
               <Row></Row>
               <Row>
                    <h1 className='text-center' style={{font: 'Diphylleia', fontSize: '1.1em', marginTop: '2%'}}><strong>November 2024 Real Estate Trends and Insights</strong></h1>
               </Row>
               <Row>
                    <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1%'}}><strong>Las Vegas, NV – December 5, 2024</strong></h2>
               </Row>
               <Row>
                    <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1%'}}><strong>LasVegasHousingMarketReport.com</strong> has unveiled its latest report, highlighting strong performance in the Las Vegas real estate market throughout November 2024.</h2>
               </Row>
               <Row>   
                    <li className="text-center"style={{fontFamily: 'Diphylleia,serif', fontSize: '1em', marginTop: '1%'}}>
                        <strong><u>Single-Family Homes</u>:</strong>
                            <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1.5%'}}>
                                <strong>Homes Sold:</strong> 1,659
                            </h2>
                            <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em'}}>
                                <strong>Median Sale Price:</strong> $480,000
                            </h2>
                            <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em'}}>
                                <strong>Average Sale Price:</strong> $599,544
                            </h2>
                            <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em'}}>
                                <strong>Average Days on Market:</strong> 43
                            </h2>
                    </li>    
               </Row>
               <Row> 
                    <li className="text-center" style={{fontFamily: 'Diphylleia,serif', fontSize: '1em', marginTop: '1%'}}>
                        <strong><u>Condos & Townhouses</u>:</strong>
                            <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1.5%'}}>
                                <strong>Units Sold:</strong> 479
                            </h2>
                            <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em'}}>
                                <strong>Median Sale Price:</strong> $300,000
                            </h2>
                            <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em'}}>
                                <strong>Average Sale Price:</strong> $320,145
                            </h2>
                            <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em'}}>
                                <strong>Average Days on Market:</strong> 46
                            </h2>
                    </li>  
               </Row>
               <Row>
                    <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1%'}}>“These figures underscore the resilience and desirability of the Las Vegas housing market,” said Tony Madaffari, spokesperson for <strong>LasVegasHousingMarketReport.com.</strong> “Demand for both single-family homes and condos continues to drive strong sales and rising prices.”</h2>
               </Row>
               <Row className="row-content1">
                    <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1%'}}>Looking for off-market properties with great investment potential? Explore <Link to="https://lasvegasoffmarketproperties.com/" style={{ color: '#670038', fontWeight: 'bold' }}>LasVegasOffMarketProperties.com</Link> for exclusive opportunities. Actively searching for a home to purchase in Las Vegas? Visit <Link to="https://lasvegashomebuyersmarket.com/" style={{ color: '#670038', fontWeight: 'bold' }}>LasVegasHomeBuyersMarket.com</Link> for tailored homebuyer services.Interested in brand-new homes? Check out <Link to='http://lasvegasnewbuilds.com/'style={{ color: '#670038', fontWeight: 'bold' }}>LasVegasNewBuilds.com</Link> for the latest developments in the area.</h2>
               </Row>
               <Row>
                    <h1 className='text-center' style={{font: 'Diphylleia', fontSize: '1.1em', marginTop: '2%'}}><strong>Why Choose LasVegasHousingMarketReport.com?</strong></h1>
               </Row>
               <Row className="row-content1">
                    <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1%'}}>We offer unparalleled, data-driven insights into the Las Vegas real estate market, serving buyers, sellers, and investors alike. Our platform provides accurate, up-to-date information and expert analysis to help you succeed in the ever-changing Las Vegas property market.</h2>
               </Row>
               <Row>
                    <h1 className='text-center' style={{font: 'Diphylleia', fontSize: '1.1em', marginTop: '2%'}}><strong>Contact Us:</strong></h1>
               </Row>
               <Row className="row-content1">
                    <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1%'}}><strong>Anthony (Tony) Madaffari, REALTOR® S.0175659</strong><br/><strong>Berkshire Hathaway Home Services Nevada Properties</strong><br/>3185 St. Rose Pkwy, Suite 100<br/><Link to="https://lasvegashousingmarketreport.com/" style={{ color: '#670038', fontWeight: 'bold' }}>LasVegasHousingMarketReport.com</Link><br/><strong>Phone:</strong> <a role='button' href='tel:+17023261282' style={{color: '#670038'}}>(702) 326-1282</a><br/><strong>Email:</strong><a href="mailto:TonyM@BHHSNV.com">TonyM@BHHSNV.com</a></h2>
               </Row>
               <Row>
                    <h1 className='text-center' style={{font: 'Diphylleia', fontSize: '1.1em', marginTop: '2%'}}><strong>Connect with us on social media to stay engaged and informed:</strong></h1>
               </Row>
               <Row>
                    <li className='text-center' style={{fontFamily: 'Diphylleia,serif', fontSize: '1em', marginTop: '1%'}}>
                    Follow our YouTube channel for video updates and in-depth discussions: <Link to= 'https://www.youtube.com/@anthonymadaffarirealtor4383'style={{ color: '#670038', fontWeight: 'bold' }}>YouTube</Link>
                    </li>
                    <li className='text-center' style={{fontFamily: 'Diphylleia,serif', fontSize: '1em', marginTop: '0.1%'}}>
                    Like us on Facebook for daily market insights and community engagement: <Link to='https://www.facebook.com/AnthonyMadaffariRealtor/'style={{ color: '#670038', fontWeight: 'bold' }}>Facebook</Link>
                    </li>
                    <li className='text-center' style={{fontFamily: 'Diphylleia,serif', fontSize: '1em', marginTop: '0.1%'}}>
                    Follow us on Instagram for stunning visuals and behind-the-scenes content: <Link to= 'https://www.instagram.com/anthonymadaffarirealtor/'style={{ color: '#670038', fontWeight: 'bold' }}>Instagram</Link>
                    </li>
                    <li className='text-center' style={{fontFamily: 'Diphylleia,serif', fontSize: '1em', marginTop: '0.1%'}}>
                    Explore our listings and reviews on Zillow to discover the finest properties Las Vegas has to offer: <Link to= "https://www.zillow.com/profile/AnthonyMadaffari/" style={{ color: '#670038', fontWeight: 'bold' }}>Zillow</Link>
                    </li>
               </Row>
               <Row>
                    <h2 className='text-center' style={{font: 'Diphylleia', fontSize: '1em', marginTop: '1%'}}><strong>Las Vegas Housing Market Report</strong> is your trusted partner in navigating Las Vegas real estate. Start your journey today and unlock the key to success in this dynamic market!</h2>
               </Row>
                <Row>
                <h2  className='text-center' style={{font: 'Diphylleia', color: '#552448', fontSize: '2em', marginTop: '1.2%'}}>Las Vegas Housing Market Report</h2>
                </Row>
                <Row >
                    <YoutubeEmbed embedId="mGsZFG4q39I" />
                </Row>
                <div className="row-content  text-center">
                    <ButtonGroup>
                        <a href="https://lasvegashomebuyersmarket.com/" target="_blank" rel="noopener noreferrer">
                            <Button className="shadow-5-strong"style={{fontFamily: 'Diphylleia', fontSize: 'medium', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                Buyers
                            </Button>
                        </a> 

                        <a href="https://anthonymadaffari.bhhsnv.com/" target="_blank" rel="noopener noreferrer">

                            <Button className="shadow-5-strong"style={{marginLeft: '0.5rem',fontFamily: 'Diphylleia', fontSize: 'medium', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                Sellers
                            </Button>
                        </a>
                        <a href="https://bhhsnv.findbuyers.com/tonym@bhhsnv.com" target="_blank" rel="noopener noreferrer">
                            <Button style={{marginLeft: '0.5rem', fontFamily: 'Diphylleia', fontSize: 'medium', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                Home Value
                            </Button>
                        </a>
                    </ButtonGroup>
                </div>
            </Container> 
                                   
            
         
    );
};


export default HeroContent;